<template>
  <item-form :is-visible="initial_form_visibility">
    <template #hide-trigger>
      <button
        class="p-2 px-4 bg-yellow-400 form__trigger-hide"
        @click="triggerHide()"
      >
        <fa-icon icon="times"></fa-icon>
      </button>
    </template>

    <template #default>
      <form action="#" @submit.prevent="submit()">
        <!-- Name -->
        <label for="candidate-name" class="uppercase tracking-wide text-sm">
          Nama
        </label>
        <input type="text" id="candidate-name" v-model="form.name" />

        <!-- Phone -->
        <label
          for="candidate-phone"
          class="block mt-6 uppercase tracking-wide text-sm"
        >
          Kontak Telepon
        </label>
        <p class="text-sm text-gray-600">Tanpa spasi, -, atau +</p>
        <input type="text" id="candidate-phone" v-model="form.phone" />

        <!-- Gender -->
        <span class="block uppercase tracking-wide text-sm mt-6 mb-2"
          >Jenis Kelamin</span
        >
        <input type="radio" id="male" :value="1" v-model="form.gender" />
        <label class="ml-2" for="male">Laki-laki</label>
        <br />
        <input type="radio" id="female" :value="0" v-model="form.gender" />
        <label class="ml-2" for="female">Perempuan</label>

        <!-- submit button -->
        <button class="block button --primary mt-6" type="submit">
          <fa-icon icon="check"></fa-icon>
          <span class="ml-2">{{
            form.id !== null ? "Simpan" : "Registrasi"
          }}</span>
        </button>
      </form>
    </template>
  </item-form>
</template>

<style lang="scss" scoped>
.form {
  @apply fixed w-full p-2 bottom-0 left-0 flex align-middle justify-center;

  .form-layer {
    @apply w-full h-screen fixed top-0 left-0;
    @apply bg-gray-900 bg-opacity-80;
  }

  .form-content {
    max-height: calc(100vh - 64.5px);

    @apply z-10 bg-white shadow w-full max-w-screen-sm p-4 overflow-y-auto;
  }
}
</style>

<script>
import _clone from "lodash/clone";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      form: {
        id: null,
        name: null,
        gender: null,
        phone: null,
      },
      formDefault: {},
    };
  },
  methods: {
    triggerHide() {
      this.HIDE_INIT_FORM();
      this.form = _clone(this.formDefault);
    },
    async submit() {
      if (this.form.id === null) {
        await this.storeCandidate(this.form);
      } else {
        await this.updateCandidate(this.form);
      }
      this.triggerHide();
    },
    /** Actions from candidate store */
    ...mapActions("candidate", ["storeCandidate", "updateCandidate"]),
    /** Mutations from candidate store */
    ...mapMutations("candidate", ["HIDE_INIT_FORM"]),
  },
  watch: {
    selected_candidate(val) {
      if (val !== null) {
        this.form = _clone(val);
      }
    },
  },
  computed: {
    /** Getters from candidate store */
    ...mapGetters("candidate", [
      "initial_form_visibility",
      "selected_candidate",
    ]),
  },
  mounted() {
    this.formDefault = _clone(this.form);
  },
  name: "InitialRegistrationForm",
  components: { ItemForm: () => import("@/components/Common/ItemForm.vue") },
};
</script>